/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// const React = require("react")
// exports.onClientEntry = () => {
//   window.onload = () => {
//     console.log("We've started!")
//   }
// }
const React = require("react")

export const onClientEntry = () => {
  window.onload = () => {
    if (document.getElementById("PhoneNumber")) {
      document
        .getElementById("PhoneNumber")
        .addEventListener("input", function(e) {
          var x = e.target.value
            .replace(/\D/g, "")
            .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
          e.target.value = !x[2]
            ? x[1]
            : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
        })
    }
    var page_width = document.body.clientWidth
    window.addEventListener("scroll", function(e) {
      var header = document.getElementById("sticky-header")
      var last_known_scroll_position = window.scrollY
      if (last_known_scroll_position > 0) {
        header.classList.add("sticky")
      } else {
        header.classList.remove("sticky")
      }
    })
  }
}
